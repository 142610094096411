/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, createContext, useContext, useEffect, useState} from 'react'
import {useLocation} from 'react-router'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: any) => void
  pageActions?: string
  setPageActions: (_action: any) => void
  pageDescription?: string
  setPageDescription: (_description: any) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageActions: (_action: any) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
})

const PageDataProvider: React.FC = ({children}) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageActions, setPageActions] = useState(undefined)
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageActions,
    setPageActions,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
  actions?: any
}

const PageTitle: FC<Props> = ({children, breadcrumbs, actions}) => {
  const {setPageTitle, setPageActions, setPageBreadcrumbs} = usePageData()
  const {pathname} = useLocation()

  useEffect(() => {
    if (children) {
      setPageTitle(children)
      SettingsPageSetup(pathname)
      window.addEventListener('resize', () => SettingsPageSetup(pathname))
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (actions) {
      setPageActions(actions)
    }
    return () => {
      setPageActions(undefined)
    }
  }, [actions])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  return <></>
}

const SettingsPageSetup = (pathname) => {
  // adds padding to PageTitle if on the settings pages
  const header = document.getElementById('gori_header_container')
  const settingsMenu = document.getElementById('aside-footer-settings')
  const navMenu = document.getElementById('gori_aside')
  const footer = document.querySelector('div.footer')
  const settingPath = '/settings/'
  const tablet = window.innerWidth < 1400 ? true : false

  if (pathname.includes(settingPath) && !tablet) {
    header?.classList.add('settings-container')
    footer?.classList.add('settings-padding')
    settingsMenu?.classList.add('show')
    navMenu?.classList.add('solid')
  } else if (!pathname.includes(settingPath)) {
    header?.classList.remove('settings-container')
    footer?.classList.remove('settings-padding')
    settingsMenu?.classList.remove('show')
    navMenu?.classList.remove('solid')
  }
}

const PageDescription: React.FC = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children)
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export {PageDescription, PageTitle, PageDataProvider, usePageData}
